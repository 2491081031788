@keyframes animateBg {
  0% { background-position: 0% 0%; }
  100% { background-position: 100% 0%; }
}

body {
  background-color: #e1e5f2;
  /* background-color: #a7bcbc; */
}


.wrapper {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
  justify-content: center;
  align-content: center;
}

.grid {
  display: flex;
  flex: 1;
  justify-content: space-evenly;
  align-content: center;
}

.options {
  display: flex;
  flex: 1;
  justify-content: space-evenly;
  align-content: center;
  margin-top: 20px;
}

.map_main {
  display: flex;
  flex: 1;
  max-width: 95%;
  padding-top: 40px;
  justify-content: center;
  align-content: center;
}

.grid_main {
  display: flex;
  flex: 1;
  justify-content: center;
  align-content: center;
}

.grid_item {
  flex-grow: 1;
}

.home_layout {
  display: flex;
}

.home_intro {
  flex: 1;
  padding: 0 20px 20px 20px;
}

.home_image {
  flex: 1;
  align-items: center;
  /* border: solid black; */
}

/* Markers */

.marker_layout {
  display: flex;
  flex-direction: column;
  margin: 50px;
}

.layout_row_one {
  display: flex;
}

.marker_image {
    flex: 1;
    /* border: solid black; */
  }

.layout_row_two {
  display: flex;
  flex: 1;
}

.layout_row_three {
  display: flex;
  /* flex: 1;  */
  /* border: solid black; */
  /* justify-content: space-between; */
}

.marker_intro {
  flex: 1;
  padding: 0 20px 20px 20px;
}

.legend {
  display: flex;
  flex: 1;
  padding: 15px;
}

.legend_group {
  display: flex;
  flex-direction: column;
}

.legend_row {
  display: flex;
  border-top: solid thin black;
}

.group_one {
  display: flex;
  flex-direction: column;
}

.group_two {
  display: flex;
  flex-direction: column;
  padding-top: 0%;
}

.icons {
  height: 20px;
  /* max-width: 30px;
  max-height: 30px; */
}

.icon_label {
  padding-left: 5px;
  font-size: 12px;
  /* margin: 0 20px 0 20px; */
}

.icon_label_p {
  margin: 0;
}

/* Plant table */

.table_layout {
  display: flex;
  flex-direction: column;
  margin: 50px;
}

.table_card{
  display: flex;
  flex-direction: row;
  border: solid thin grey;
  margin-bottom: 20px;
  box-shadow: 2px 2px 2px 1px rgba(102, 74, 74, 0.2);
}

.table_img {
  padding: 20px;
  max-width: 250px;
  max-height: 200px;
}

.table_intro {
  padding: 20px;
}

.sticky_header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 100;
  background-color: #1f7a8c;
}

.heading {
  flex: 1;
  justify-content: center;
  background-color: #bfdbf7;
}

img {
  max-width: 100%;
}

h1 {
  font-size: 1.5em;
}

h2 {
  font-size: 1em;
}

button {
  font-weight: bold;
  border: none;
  background-color: transparent;
  text-decoration: none;
  cursor: pointer;
  outline: none;
  color: #1f7a8c;
}

button,
  select {
  text-transform: none;
  }

button,
input,
optgroup,
select,
textarea {
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  margin: 0; /* 2 */
}

button:hover,
button:focus {
    opacity: 1;
}

button:focus {
    outline-offset: -4px;
}

button:active {
    transform: scale(0.99);
}

hr {
  border: none;
  border-top: 3px double #333;
  color: #333;
  overflow: visible;
  text-align: center;
  height: 5px;
  margin-top: 20px;
}

hr:after {
  background: #fff;
  content: '§';
  padding: 0 4px;
  position: relative;
  top: -13px;
}

/* Modal */

.modal {
  display: block;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  background: white;
  box-shadow: 0 0 60px 10px rgba(0, 0, 0, 0.9);
  max-width: 100%;
  height: 150px;
  max-height: 100%;
  padding: 20px 50px 20px 20px;
  overflow: auto;
}

.area_modal {
  display: block;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 200px;
  background: white;
  box-shadow: 0 0 60px 10px rgba(0, 0, 0, 0.9);
  max-width: 100%;
  height: 300px;
  max-height: 100%;
  padding: 20px 50px 20px 20px;
  overflow: auto;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
  background: rgba(0, 0, 0, 0.6);
}

.modal-guts {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 20px 50px 20px 20px;
}

.modal .close-button {
  position: absolute;
  z-index: 1;
  top: 10px;
  right: 20px;
  border: 0;
  color: black;
  padding: 5px 10px;
  font-size: 1.3rem;
}

.modal.closed {
  display: none;
}

.area_modal .close-button {
  position: absolute;
  z-index: 1;
  top: 10px;
  right: 20px;
  border: 0;
  color: black;
  padding: 5px 10px;
  font-size: 1.3rem;
}

.are_modal.closed {
  display: none;
}

.main-container {
  width: 100%;
  height: 100%;
  display: flex;
  margin: 0;
  justify-content: center;
  align-items: center;
  background-color: #fafafa;
}

.table-container {
  display: flex;
  flex-flow: column nowrap;
  background-color: white;
  width: 80%;
  margin: 0 auto;
  border-radius: 4px;
  border: 1px solid #DADADA;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, .08);
}

.table-row {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  border-bottom: 1px solid #dadada;
}

.row-item {
  display: flex;
  flex: 1;
  font-size: 14px;
  padding: 8px 0;
  justify-content: flex-start;
  align-items: center;
  transition: all 0.15s ease-in-out;
}

.row-sub-container .row-item {
  padding: 8px 0;
  border-bottom: 1px solid #dadada;
}

.table-row:last-child,
.row-sub-container .row-item:last-child {
  border-bottom: 0;
}

@media only screen
  and (min-device-width: 320px)
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .home_layout {
      flex-direction: column;
    }

    .layout_row_one {
      flex-direction: column;
    }

    .legend_group {
      flex-direction: column;
    }

    .marker_image {
      width: 100%;
      order:1;
    }

    .marker_intro {
      order:2;
    }

    .home_intro {
      order: 2;
    }

    .home_image {
      width: 100%;
      order: 1;
    }

    .table {
      order: 1;
    }

    .table_card {
      flex-direction: column;
    }

    .table_img {
      order:2
    }

    .table_intro {
      order: 2;
    }
}

@media only screen
  and (min-device-width: 320px)
  and (max-device-width: 568px)
  and (-webkit-min-device-pixel-ratio: 2) {

}

/* ----------- Non-Retina Screens -----------
@media screen
  and (min-device-width: 1200px)
  and (max-device-width: 1600px)
  and (-webkit-min-device-pixel-ratio: 1) {
    .home_layout {
      flex-direction: column;
    }
    .home_intro {
      order: 2;
    }
    .home_image {
      order: 1;
    }
}

/* ----------- Retina Screens ----------- */
/* @media screen
  and (min-device-width: 1200px)
  and (max-device-width: 1600px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (min-resolution: 192dpi) {
    .home_layout {
      flex-direction: column;
    }
    .home_intro {
      order: 2;
    }
    .home_image {
      order: 1;
    }
}  */
