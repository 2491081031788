p {
    text-align: start;
}

.imageBox {
    display: 'flex';
    flex-wrap: 'wrap';
    justify-content: 'space-around';
    overflow: 'hidden';
}

.gridList {
    flex-wrap: 'nowrap';
    transform: 'translateZ(0)';
}

.titleBar {
    background:
      linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%);
}

.plantList {
    
}