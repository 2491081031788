.pvb-1 {
  opacity: 0.5;
}

.pvb-2, .pvb-53 {
  fill: #fff;
}

.pvb-10, .pvb-11, .pvb-13, .pvb-14, .pvb-2, .pvb-21, .pvb-23, .pvb-24, .pvb-25, .pvb-26, .pvb-27, .pvb-28, .pvb-3, .pvb-30, .pvb-31, .pvb-32, .pvb-33, .pvb-34, .pvb-35, .pvb-36, .pvb-37, .pvb-38, .pvb-39, .pvb-4, .pvb-40, .pvb-41, .pvb-42, .pvb-43, .pvb-44, .pvb-46, .pvb-48, .pvb-49, .pvb-5, .pvb-50, .pvb-51, .pvb-52, .pvb-53, .pvb-6, .pvb-7, .pvb-8, .pvb-9 {
  stroke: #000;
}

.pvb-10, .pvb-11, .pvb-13, .pvb-14, .pvb-16, .pvb-17, .pvb-18, .pvb-19, .pvb-2, .pvb-20, .pvb-21, .pvb-22, .pvb-23, .pvb-24, .pvb-25, .pvb-26, .pvb-27, .pvb-28, .pvb-3, .pvb-30, .pvb-31, .pvb-32, .pvb-33, .pvb-34, .pvb-35, .pvb-36, .pvb-37, .pvb-38, .pvb-39, .pvb-40, .pvb-41, .pvb-42, .pvb-43, .pvb-44, .pvb-46, .pvb-48, .pvb-49, .pvb-5, .pvb-50, .pvb-51, .pvb-52, .pvb-53, .pvb-6, .pvb-7, .pvb-8, .pvb-9 {
  stroke-miterlimit: 10;
}

.pvb-2 {
  stroke-width: 3.03px;
}

.pvb-3, .pvb-4 {
  fill: #d4d6d5;
}

.pvb-3 {
  stroke-width: 2.99px;
}

.pvb-4 {
  stroke-linejoin: round;
  stroke-width: 3.01px;
}

.pvb-5 {
  fill: aqua;
}

.pvb-10, .pvb-11, .pvb-31, .pvb-5, .pvb-6, .pvb-7, .pvb-8, .pvb-9 {
  stroke-width: 4px;
}

.pvb-10, .pvb-11, .pvb-15, .pvb-5, .pvb-6, .pvb-7, .pvb-8, .pvb-9 {
  opacity: 0.2;
}

.pvb-6 {
  fill: #ff0;
}

.pvb-7 {
  fill: #009245;
}

.pvb-8 {
  fill: #fbb03b;
}

.pvb-9 {
  fill: #662d91;
}

.pvb-10 {
  fill: red;
}

.pvb-11 {
  fill: #c69c6d;
}

.pvb-12 {
  opacity: 0.3;
}

.pvb-13, .pvb-14, .pvb-20, .pvb-21, .pvb-24, .pvb-25, .pvb-26, .pvb-27, .pvb-28, .pvb-29, .pvb-31, .pvb-33, .pvb-34, .pvb-35, .pvb-36, .pvb-37, .pvb-38, .pvb-39, .pvb-40, .pvb-41, .pvb-42, .pvb-43, .pvb-44, .pvb-46, .pvb-48, .pvb-49, .pvb-50, .pvb-51, .pvb-52 {
  fill: none;
}

.pvb-13, .pvb-14, .pvb-16, .pvb-17, .pvb-18, .pvb-19 {
  stroke-linecap: square;
}

.pvb-13, .pvb-20, .pvb-21, .pvb-22, .pvb-23, .pvb-30, .pvb-48 {
  stroke-width: 2px;
}

.pvb-14, .pvb-17 {
  stroke-width: 2.22px;
}

.pvb-16, .pvb-17, .pvb-18, .pvb-19 {
  fill: #ccc;
  stroke: #999;
}

.pvb-16 {
  stroke-width: 1.65px;
}

.pvb-18 {
  stroke-width: 3.88px;
}

.pvb-19 {
  stroke-width: 2.49px;
}

.pvb-20, .pvb-22 {
  stroke: #333;
}

.pvb-22, .pvb-23 {
  fill: #00a99d;
}

.pvb-24 {
  stroke-width: 3px;
}

.pvb-25 {
  stroke-width: 0.75px;
}

.pvb-26 {
  stroke-width: 0.58px;
}

.pvb-28 {
  stroke-width: 1.18px;
}

.pvb-33, .pvb-34, .pvb-35, .pvb-36, .pvb-37, .pvb-38, .pvb-39, .pvb-40, .pvb-41, .pvb-42 {
  stroke-linecap: round;
  stroke-width: 8px;
}

.pvb-34 {
  stroke-dasharray: 0 23.96;
}

.pvb-35 {
  stroke-dasharray: 0 23.84;
}

.pvb-36 {
  stroke-dasharray: 0 23.96;
}

.pvb-37 {
  stroke-dasharray: 0 22.75;
}

.pvb-38 {
  stroke-dasharray: 0 24.18;
}

.pvb-39 {
  stroke-dasharray: 0 24.73;
}

.pvb-40 {
  stroke-dasharray: 0 24.14;
}

.pvb-41 {
  stroke-dasharray: 0 18.08;
}

.pvb-42 {
  stroke-dasharray: 0 24;
}

.pvb-43 {
  stroke-width: 1.97px;
}

.pvb-44 {
  stroke-width: 1.81px;
}

.pvb-45 {
  opacity: 0.36;
}

.pvb-46 {
  stroke-width: 1.78px;
}

.pvb-47 {
  opacity: 0.22;
}

.pvb-48 {
  stroke-dasharray: 4 4;
}

.pvb-49 {
  stroke-width: 1.31px;
}

.pvb-50 {
  stroke-width: 0.49px;
}

.pvb-51 {
  stroke-width: 0.32px;
}

.pvb-52 {
  stroke-width: 0.32px;
}

.abs-1, .abs-2, .abs-3, .abs-4, .abs-5, .abs-6, .abs-7 {
  fill: none;
}

.abs-1, .abs-3, .abs-4, .abs-5, .abs-6 {
  stroke: #000;
}

.abs-1, .abs-2, .abs-3, .abs-4, .abs-5, .abs-6 {
  stroke-miterlimit: 10;
}

.abs-1 {
  stroke-width: 3px;
}

.abs-2 {
  stroke: #333;
  stroke-width: 2px;
}

.abs-3 {
  stroke-width: 0.75px;
}

.abs-4 {
  stroke-width: 0.58px;
}

.abs-6 {
  stroke-width: 1.18px;
}

.cls-1 {
  opacity: 0.5;
}

.cls-2 {
  fill: #fff;
  stroke-width: 3.03px;
}

.cls-11,
.cls-13,
.cls-14,
.cls-15,
.cls-16,
.cls-17,
.cls-18,
.cls-2,
.cls-20,
.cls-22,
.cls-23,
.cls-24,
.cls-25,
.cls-26,
.cls-27,
.cls-3,
.cls-4 {
  stroke: #000;
}

.cls-10,
.cls-11,
.cls-12,
.cls-13,
.cls-14,
.cls-15,
.cls-16,
.cls-17,
.cls-18,
.cls-2,
.cls-20,
.cls-22,
.cls-23,
.cls-24,
.cls-25,
.cls-26,
.cls-27,
.cls-3,
.cls-6,
.cls-7,
.cls-8,
.cls-9 {
  stroke-miterlimit: 10;
}

.cls-3,
.cls-4 {
  fill: #d4d6d5;
}

.cls-3 {
  stroke-width: 2.99px;
}

.cls-4 {
  stroke-linejoin: round;
  stroke-width: 3.01px;
}

.cls-5 {
  opacity: 0.2;
}

.cls-6,
.cls-7,
.cls-8,
.cls-9 {
  fill: none;
  stroke: #999;
  stroke-linecap: square;
}

.cls-6 {
  stroke-width: 1.65px;
}

.cls-7 {
  stroke-width: 2.22px;
}

.cls-8 {
  stroke-width: 3.88px;
}

.cls-9 {
  stroke-width: 2.49px;
}

.cls-10,
.cls-11,
.cls-15,
.cls-17,
.cls-18,
.cls-20,
.cls-22,
.cls-23,
.cls-24,
.cls-25,
.cls-26,
.cls-27 {
  fill: none;
}

.cls-10,
.cls-12 {
  stroke: #333;
}

.cls-10,
.cls-11,
.cls-12,
.cls-13,
.cls-14,
.cls-22 {
  stroke-width: 2px;
}

.cls-12,
.cls-13 {
  fill: #00a99d;
}

.cls-15 {
  stroke-width: 4px;
}

.cls-17 {
  stroke-width: 1.97px;
}

.cls-18 {
  stroke-width: 1.81px;
}

.cls-19 {
  opacity: 0.36;
}

.cls-20 {
  stroke-width: 1.78px;
}

.cls-21 {
  opacity: 0.22;
}

.cls-22 {
  stroke-dasharray: 4 4;
}

.cls-23 {
  stroke-width: 1.31px;
}

.cls-24 {
  stroke-width: 3px;
}

.cls-25 {
  stroke-width: 0.49px;
}

.cls-26 {
  stroke-width: 0.32px;
}

.cls-27 {
  stroke-width: 0.32px;
}

.st0 {
  fill: #333333;
}

.st1 {
  display: none;
}

.st2 {
  display: inline;
}

.st3 {
  fill: none;
}

.st4 {
  font-family: 'Handlee-Regular';
}

.st5 {
  font-size: 23px;
}

.st6 {
  fill: none;
  stroke: #000000;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-miterlimit: 10;
}

.st7 {
  fill: none;
  stroke: #333333;
  stroke-miterlimit: 10;
}

.st8 {
  fill: none;
  stroke: #333333;
  stroke-width: 2;
  stroke-miterlimit: 10;
}

.st9 {
  fill: #FFFFFF;
  stroke: #000000;
  stroke-width: 2.2238;
  stroke-linecap: square;
  stroke-miterlimit: 10;
}

.st10 {
  fill: none;
  stroke: #000000;
  stroke-width: 2;
  stroke-miterlimit: 10;
}

.st11 {
  fill: none;
  stroke: #000000;
  stroke-width: 3;
  stroke-linecap: round;
  stroke-miterlimit: 10;
}

.st12 {
  fill: none;
  stroke: #000000;
  stroke-width: 4;
  stroke-linecap: round;
  stroke-miterlimit: 10;
  stroke-dasharray: 0, 10;
}

.st13 {
  fill: none;
  stroke: #000000;
  stroke-width: 0.9081;
  stroke-miterlimit: 10;
}

.st14 {
  display: inline;
  fill: none;
  stroke: #000000;
  stroke-width: 3;
  stroke-miterlimit: 10;
}

.st15 {
  opacity: 0.5;
}

.st16 {
  fill: #FFFFFF;
  stroke: #000000;
  stroke-width: 3.0256;
  stroke-miterlimit: 10;
}

.st17 {
  fill: #D4D6D5;
  stroke: #000000;
  stroke-width: 2.9938;
  stroke-miterlimit: 10;
}

.st18 {
  fill: #D4D6D5;
  stroke: #000000;
  stroke-width: 3.0132;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

.st19 {
  display: none;
  fill: #D4D6D5;
  stroke: #000000;
  stroke-width: 3.0132;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

.st20 {
  opacity: 0.71;
}

.st21 {
  fill: none;
  stroke: #000000;
  stroke-width: 2;
  stroke-linecap: square;
  stroke-miterlimit: 10;
}

.st22 {
  fill: none;
  stroke: #000000;
  stroke-width: 2.2238;
  stroke-linecap: square;
  stroke-miterlimit: 10;
}

.st23 {
  opacity: 0.75;
}

.st24 {
  opacity: 0.8;
}

.st25 {
  fill: #CCCCCC;
  stroke: #999999;
  stroke-width: 1.6464;
  stroke-linecap: square;
  stroke-miterlimit: 10;
}

.st26 {
  fill: #CCCCCC;
  stroke: #999999;
  stroke-width: 2.2238;
  stroke-linecap: square;
  stroke-miterlimit: 10;
}

.st27 {
  fill: #CCCCCC;
  stroke: #999999;
  stroke-width: 3.8808;
  stroke-linecap: square;
  stroke-miterlimit: 10;
}

.st28 {
  fill: #CCCCCC;
  stroke: #999999;
  stroke-width: 2.488;
  stroke-linecap: square;
  stroke-miterlimit: 10;
}

.st29 {
  fill: #F2F2F2;
  stroke: #333333;
  stroke-width: 2;
  stroke-miterlimit: 10;
}

.st30 {
  fill: #FFFFFF;
  stroke: #333333;
  stroke-width: 2;
  stroke-miterlimit: 10;
}

.st31 {
  fill: #F2F2F2;
  stroke: #000000;
  stroke-width: 2;
  stroke-miterlimit: 10;
}

.st32 {
  font-size: 16px;
}

/* Planting Plan 2021 section styles */
.st33 {
  fill: #FFFFFF;
  stroke: #000000;
  stroke-width: 2;
  stroke-miterlimit: 10;
}

.st34 {
  font-family: 'MyriadPro-Regular';
}

.st35 {
  font-size: 22px;
}

.st36 {
  fill: none;
  stroke: #000000;
  stroke-width: 3;
  stroke-miterlimit: 10;
}

.st37 {
  font-size: 12.6797px;
}

.st38 {
  fill: none;
  stroke: #000000;
  stroke-width: 0.75;
  stroke-miterlimit: 10;
}

.st39 {
  font-size: 12.3379px;
}

.st40 {
  fill: none;
  stroke: #000000;
  stroke-width: 0.5781;
  stroke-miterlimit: 10;
}

.st41 {
  font-size: 14.0152px;
}

.st42 {
  font-size: 16.6399px;
}

.st43 {
  fill: none;
  stroke: #000000;
  stroke-miterlimit: 10;
}

.st44 {
  font-size: 14px;
}

.st45 {
  fill: none;
  stroke: #000000;
  stroke-width: 1.1761;
  stroke-miterlimit: 10;
}

.st46 {
  stroke: #000000;
  stroke-width: 2;
  stroke-miterlimit: 10;
}

.st47 {
  fill: none;
  stroke: #000000;
  stroke-width: 4;
  stroke-miterlimit: 10;
}

.st48 {
  stroke: #000000;
  stroke-miterlimit: 10;
}

.st49 {
  display: none;
  fill: none;
  stroke: #000000;
  stroke-width: 4;
  stroke-linecap: round;
  stroke-miterlimit: 10;
  stroke-dasharray: 0, 23;
}

.st50 {
  fill: none;
  stroke: #000000;
  stroke-width: 4;
  stroke-linecap: round;
  stroke-miterlimit: 10;
}

.st51 {
  fill: none;
  stroke: #000000;
  stroke-width: 4;
  stroke-linecap: round;
  stroke-miterlimit: 10;
  stroke-dasharray: 0, 24.0466;
}

.st52 {
  fill: none;
  stroke: #000000;
  stroke-width: 4;
  stroke-linecap: round;
  stroke-miterlimit: 10;
  stroke-dasharray: 0, 22.7828;
}

.st53 {
  fill: none;
  stroke: #000000;
  stroke-width: 4;
  stroke-linecap: round;
  stroke-miterlimit: 10;
  stroke-dasharray: 0, 23.6645;
}

.st54 {
  fill: none;
  stroke: #000000;
  stroke-width: 4;
  stroke-linecap: round;
  stroke-miterlimit: 10;
  stroke-dasharray: 0, 24.2666;
}

.st55 {
  fill: none;
  stroke: #000000;
  stroke-width: 4;
  stroke-linecap: round;
  stroke-miterlimit: 10;
  stroke-dasharray: 0, 22.7467;
}

.st56 {
  fill: none;
  stroke: #000000;
  stroke-width: 4;
  stroke-linecap: round;
  stroke-miterlimit: 10;
  stroke-dasharray: 0, 24.1756;
}

.st57 {
  fill: none;
  stroke: #000000;
  stroke-width: 4;
  stroke-linecap: round;
  stroke-miterlimit: 10;
  stroke-dasharray: 0, 24.6432;
}

.st58 {
  fill: none;
  stroke: #000000;
  stroke-width: 4;
  stroke-linecap: round;
  stroke-miterlimit: 10;
  stroke-dasharray: 0, 24.1388;
}

.st59 {
  fill: none;
  stroke: #000000;
  stroke-width: 4;
  stroke-linecap: round;
  stroke-miterlimit: 10;
  stroke-dasharray: 0, 18.0829;
}

.st60 {
  display: none;
  fill: none;
  stroke: #000000;
  stroke-width: 4;
  stroke-linecap: round;
  stroke-miterlimit: 10;
  stroke-dasharray: 0, 24;
}

.st61 {
  fill: none;
  stroke: #000000;
  stroke-width: 4;
  stroke-linecap: round;
  stroke-miterlimit: 10;
  stroke-dasharray: 0, 24;
}

.st62 {
  fill: none;
  stroke: #000000;
  stroke-width: 1.9707;
  stroke-miterlimit: 10;
}

.st63 {
  fill: none;
  stroke: #000000;
  stroke-width: 1.8068;
  stroke-miterlimit: 10;
}

.st64 {
  opacity: 0.36;
}

.st65 {
  fill: none;
  stroke: #000000;
  stroke-width: 1.7783;
  stroke-miterlimit: 10;
}

.st66 {
  opacity: 0.22;
}

.st67 {
  fill: none;
  stroke: #000000;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke-dasharray: 4, 4;
}

.st68 {
  fill: none;
  stroke: #000000;
  stroke-width: 1.3097;
  stroke-miterlimit: 10;
}

.st69 {
  font-size: 33px;
}

.st70 {
  font-size: 15px;
}

.st71 {
  font-size: 19px;
}

.st72 {
  fill: none;
  stroke: #000000;
  stroke-width: 0.4862;
  stroke-miterlimit: 10;
}

.st73 {
  fill: none;
  stroke: #000000;
  stroke-width: 0.315;
  stroke-miterlimit: 10;
}

.st74 {
  fill: none;
  stroke: #000000;
  stroke-width: 0.3162;
  stroke-miterlimit: 10;
}

.st75 {
  fill: #FFFFFF;
  stroke: #000000;
  stroke-miterlimit: 10;
}

.st76 {
  font-size: 12px;
}

.st77 {
  opacity: 0.2;
  fill: #00FFFF;
  stroke: #000000;
  stroke-width: 4;
  stroke-miterlimit: 10;
}

.st78 {
  opacity: 0.2;
  fill: #FFFF00;
  stroke: #000000;
  stroke-width: 4;
  stroke-miterlimit: 10;
}

.st79 {
  opacity: 0.2;
  fill: #009245;
  stroke: #000000;
  stroke-width: 4;
  stroke-miterlimit: 10;
}

.st80 {
  opacity: 0.2;
  fill: #FBB03B;
  stroke: #000000;
  stroke-width: 4;
  stroke-miterlimit: 10;
}

.st81 {
  opacity: 0.2;
  fill: #662D91;
  stroke: #000000;
  stroke-width: 4;
  stroke-miterlimit: 10;
}

.st82 {
  opacity: 0.2;
  fill: #FF0000;
  stroke: #000000;
  stroke-width: 4;
  stroke-miterlimit: 10;
}

.st83 {
  opacity: 0.2;
  fill: #C69C6D;
  stroke: #000000;
  stroke-width: 4;
  stroke-miterlimit: 10;
}
